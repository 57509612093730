import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
import { useSPAConfigQuery } from './api/SpaQueries.ts'
import { AppRoutes } from './AppRoutes'
import { ClientProfileProvider } from './contexts/ClientProfileProvider.tsx'
import { AuthProvider } from '@/contexts/auth/AuthProvider.tsx'
import { ErrorBoundaryModal } from '@/components/error/ErrorModal.tsx'

export function App() {
  const configQuery = useSPAConfigQuery()
  const navigate = useNavigate()

  const config = configQuery.data ?? null

  const oktaAuth = useMemo(() => {
    if (config) {
      return new OktaAuth({
        pkce: true,
        issuer: config.issuerUri,
        clientId: config.clientId,
        scopes: ['openid', 'profile', 'email'],
        redirectUri: window.location.origin + '/login/callback',
      })
    }
  }, [config])

  // When returning from a login redirect this function
  // navigates the client to the original SPA route that was
  // requested.
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin) || '/', {
      replace: true,
    })
  }

  if (oktaAuth) {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <ErrorBoundary FallbackComponent={ErrorBoundaryModal}>
          <AuthProvider>
            <ClientProfileProvider>
              <AppRoutes />
            </ClientProfileProvider>
          </AuthProvider>
        </ErrorBoundary>
      </Security>
    )
  }

  return <></>
}
