import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import VerticalPropertyItem from '@/features/serviceactivity/detailsDrawer/VerticalPropertyItem.tsx'
import { Case } from '@/generated'
import WorkOrdersPanel from '@/features/serviceactivity/detailsDrawer/workOrders/WorkOrdersPanel.tsx'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { PropsWithChildren } from 'react'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { calcDaysBetween } from '@/helpers/MomentHelpers.ts'
import { pluralize } from '@/helpers/StringHelpers.ts'
import CaseAssetDetails from '@/features/serviceactivity/detailsDrawer/CaseAssetDetails.tsx'
import CaseBanners from '@/features/serviceactivity/detailsDrawer/CaseBanners.tsx'
import TruncatedText from '@/components/text/TruncatedText.tsx'

export type CaseDetailsContentProps = {
  caseData: Case
}

export default function CaseDetailsContent({
  caseData,
}: Readonly<CaseDetailsContentProps>) {
  const {
    id,
    caseNumber,
    assetId,
    subject,
    creationReason,
    closedAt,
    createdAt,
    updatedAt,
    workOrderCount,
    origin,
    lastPublicOutreachDate,
    summary,
    caseType,
    rmaCaseNumber,
  } = caseData
  const { isResidentialClient } = useFeatureFlags()
  const lastPublicOutreachDays =
    lastPublicOutreachDate && calcDaysBetween(lastPublicOutreachDate)
  const showTabsSection = workOrderCount !== null && workOrderCount > 0
  return (
    <>
      <div className="tw-mb-10 tw-flex tw-flex-col tw-gap-8 tw-bg-white">
        <CaseBanners caseData={caseData} />
        <div className="tw-flex tw-items-end tw-justify-between">
          <div>
            <h2 className="tw-text-xl tw-font-medium tw-text-gray-900">
              {subject}
            </h2>
            {assetId && <CaseAssetDetails assetId={assetId} />}
          </div>
          <div className="tw-grow tw-text-right tw-text-sm tw-text-gray-500">
            <div>
              Last Updated: {DateTimeFormat.format(new Date(updatedAt))}
            </div>
            <div>Created: {DateTimeFormat.format(new Date(createdAt))}</div>
          </div>
        </div>
        <div className="tw-flex tw-items-start tw-gap-6">
          <VerticalPropertyItem label="Case Number">
            {caseNumber}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Creation Reason">
            {creationReason}
          </VerticalPropertyItem>

          {isResidentialClient && (
            <>
              <VerticalPropertyItem label="Case Type">
                {caseType}
              </VerticalPropertyItem>
              <VerticalPropertyItem label="Case Origin">
                {origin}
              </VerticalPropertyItem>
            </>
          )}

          <VerticalPropertyItem label="Days Open">
            {calcDaysBetween(createdAt, closedAt ?? undefined)} days
          </VerticalPropertyItem>

          {isResidentialClient && (
            <VerticalPropertyItem label="Last Customer Outreach">
              {lastPublicOutreachDays &&
                `${lastPublicOutreachDays} ${pluralize(lastPublicOutreachDays, 'day', 'days')} ago`}
            </VerticalPropertyItem>
          )}
        </div>
        {rmaCaseNumber && (
          <div className="tw-text-gray-700">
            <span className="tw-font-medium">RMA Claim Number: </span>
            {rmaCaseNumber}
          </div>
        )}
        {isResidentialClient && summary && (
          <TruncatedText
            lineCount={2}
            className="tw-whitespace-pre-wrap tw-text-gray-500"
          >
            <span className="tw-font-medium tw-text-gray-700">Summary: </span>
            {summary}
          </TruncatedText>
        )}
      </div>
      {showTabsSection && (
        <div className="-tw-mx-12 tw-grow tw-bg-gray-50">
          <TabGroup>
            <TabList className="tw-flex tw-gap-2 tw-border-b tw-border-gray-200 tw-bg-white tw-px-12">
              <CaseTab>
                <div className="tw-inline-flex tw-gap-1.5">
                  Work Orders{' '}
                  <span className="tw-flex tw-h-5 tw-min-w-5 tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-100 tw-p-1 tw-text-blue-600">
                    {workOrderCount}
                  </span>
                </div>
              </CaseTab>
            </TabList>
            <TabPanels className="tw-px-4 tw-pt-4">
              <TabPanel>
                <WorkOrdersPanel caseId={id} />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      )}
    </>
  )
}

function CaseTab({ children }: Readonly<PropsWithChildren>) {
  return (
    <Tab className="-tw-mb-[1px] tw-border-b tw-border-gray-200 tw-p-4 tw-text-sm tw-font-medium tw-text-gray-500 data-[selected]:tw-border-blue-600 data-[selected]:tw-text-blue-600">
      {children}
    </Tab>
  )
}
