import { Badge } from '@/components/core/badges/Badge.tsx'
import { workOrderStatusBadgeColor } from '@/helpers/ServiceActivityHelper.ts'
import VerticalPropertyItem from '@/features/serviceactivity/detailsDrawer/VerticalPropertyItem.tsx'
import {
  DateTimeFormat,
  formatLocalDate,
} from '@/helpers/NativeDateTimeHelpers.ts'
import Divider from '@/components/layout/Divider.tsx'
import QuoteDetails from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/QuoteDetails.tsx'
import { WorkOrder } from '@/generated'

type WorkOrderDetailsProps = {
  workOrder: WorkOrder
}

export function WorkOrderDetails({
  workOrder,
}: Readonly<WorkOrderDetailsProps>) {
  return (
    <div className="tw-flex tw-gap-8 tw-px-4 tw-py-6">
      <div className="tw-grow">
        <div className="tw-mb-8 tw-flex tw-items-center tw-gap-4">
          <h3 className="tw-text-xl tw-font-medium tw-text-gray-900">
            {workOrder.workOrderNumber}
          </h3>
          <Badge color={workOrderStatusBadgeColor(workOrder)} size="large">
            {workOrder.status}
          </Badge>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-8">
          <div className="tw-grid tw-grid-cols-2 tw-gap-8">
            <VerticalPropertyItem label="Progress">
              {workOrder.subStatus}
            </VerticalPropertyItem>
            <VerticalPropertyItem label="Schedule Date">
              {workOrder.scheduleDate &&
                formatLocalDate(workOrder.scheduleDate)}
            </VerticalPropertyItem>
            {workOrder.rmaCaseNumber && (
              <VerticalPropertyItem
                label="RMA Claim Number"
                className="tw-col-span-2"
              >
                {workOrder.rmaCaseNumber}
              </VerticalPropertyItem>
            )}
            <VerticalPropertyItem label="Created Date">
              {DateTimeFormat.format(new Date(workOrder.createdAt))}
            </VerticalPropertyItem>
            <VerticalPropertyItem label="Last Updated">
              {DateTimeFormat.format(new Date(workOrder.updatedAt))}
            </VerticalPropertyItem>
          </div>
          <Divider />
          <div>
            <VerticalPropertyItem label="Work Order Summary Notes">
              <span className="tw-whitespace-pre-wrap">
                {workOrder.summary}
              </span>
            </VerticalPropertyItem>
          </div>
        </div>
      </div>
      {workOrder.quoteDetails && (
        <div className="tw-w-[440px] tw-shrink-0">
          <QuoteDetails quoteDetails={workOrder.quoteDetails} />
        </div>
      )}
    </div>
  )
}
