import { WorkOrderQuoteDetails } from '@/generated'
import QuoteAction from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/footer/QuoteAction.tsx'
import QuoteTitle from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/header/title/QuoteTitle.tsx'
import SplitQuoteAlert from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/body/split/SplitQuoteAlert.tsx'
import QuoteDisclosure from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/body/client/disclosure/QuoteDisclosure.tsx'

type Props = Readonly<{
  quoteDetails: WorkOrderQuoteDetails
}>

export default function QuoteDetails({ quoteDetails }: Readonly<Props>) {
  const { billableParty, quotes, aggregateQuoteStatus } = quoteDetails
  const isSplit = billableParty === 'Split'
  if (isSplit) {
    return <SplitQuoteAlert aggregateQuoteStatus={aggregateQuoteStatus} />
  }
  if (!quotes?.length) {
    return null
  }
  const isSingleQuote = quotes.length === 1

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-6 tw-rounded-xl tw-border tw-border-gray-200 tw-p-6">
      <QuoteTitle quotesLength={quotes.length} />
      <div className="tw-flex tw-flex-col tw-gap-4">
        {quotes.map(quote => (
          <QuoteDisclosure
            key={quote.id}
            quote={quote}
            isSingleQuote={isSingleQuote}
          />
        ))}
      </div>
      <QuoteAction />
    </div>
  )
}
